import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45user_45global from "/vercel/path0/middleware/01.user.global.ts";
import _02_45vertical_45scroll_45position_45global from "/vercel/path0/middleware/02.vertical-scroll-position.global.ts";
import _03_45disable_45dev_45features_45global from "/vercel/path0/middleware/03.disable-dev-features.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45user_45global,
  _02_45vertical_45scroll_45position_45global,
  _03_45disable_45dev_45features_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  "can-manage-mentors": () => import("/vercel/path0/middleware/can-manage-mentors.ts"),
  guest: () => import("/vercel/path0/middleware/guest.ts")
}