import { getUnixTime, subMinutes } from "date-fns";
import type { LocationQuery } from "vue-router";
import { toast } from "vue3-toastify";
import type { IAuthModel, IRegisterUserModel, IAuthUserModel, IUserModel } from "~/models";
import type { ResetPasswordPayload } from "~/services/auth.service";
import {
  fetchUserFromTokenClient,
  loginViaAccountProvider,
  loginViaEmail,
  registerViaEmail,
  requestPasswordReset,
  resetPassword,
} from "~/services/auth.service";
import type { ApiErrorResponse, LearningType, UserSettingsType } from "~/utils/types";
import { AUTH_USER_UPDATE_INTERVAL, COOKIE_KEYS, LEARNING_TYPE, RESPONSE_STATUS, USER_SETTINGS } from "~/constants";
import { useCookies } from "@vueuse/integrations/useCookies";

type RequestResetType = {
  email: string;
  callback_url: string;
};

type AuthErrorType = IRegisterUserModel | IAuthModel | RequestResetType | ResetPasswordPayload;

export const useAuthStore = defineStore(
  "auth",
  () => {
    const user = shallowRef<IUserModel | undefined | null>(null);
    const authUser = ref<IAuthUserModel>();
    const authUserUpdatedAt = ref<number>();
    const requesting = ref<boolean>(false);
    const errors = shallowRef<ApiErrorResponse<AuthErrorType> | undefined | null>(null);
    const regData = ref<IRegisterUserModel>({
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
    const authData = ref<IAuthModel>({
      username: "",
      password: "",
    });
    const authTokenExpiresAt = ref<number>(0);

    const isCreatorModeEnabled = ref(false);

    const isAuthenticated = computed(() => Boolean(authUser.value) || Boolean(user.value));

    const isValidAuthToken = () => authTokenExpiresAt.value > getUnixTime(new Date());

    const userLearningType = computed<LearningType>(() => {
      const type = getUserSetting(USER_SETTINGS.LearningType);

      if (type) {
        return type as LearningType;
      }

      return LEARNING_TYPE.Visual;
    });

    const fetchError = (property: string) => getValidationError<AuthErrorType>(property, errors.value);

    const registerUser = async () => {
      if (requesting.value) {
        return;
      }

      errors.value = null;
      requesting.value = true;
      authTokenExpiresAt.value = 0;

      regData.value.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      regData.value.password_confirmation = regData.value.password;

      const { data, error } = await registerViaEmail(regData.value);
      requesting.value = false;
      errors.value = error?.data;

      if (errors.value || !data) {
        const message = errors.value?.message;

        if (message) {
          toast.error(message);
        }

        return;
      }

      user.value = data.data.user;
      authTokenExpiresAt.value = data.data.expires_at;

      toast.success("Welcome! Complete your profile for optimal experience.");
    };

    const loginUser = async () => {
      if (requesting.value) {
        return;
      }

      errors.value = null;
      requesting.value = true;
      authTokenExpiresAt.value = 0;

      const { data, error } = await loginViaEmail(authData.value);
      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message = errors.value.message;

        if (message) {
          toast.error(message);
        }

        return;
      }

      if (data) {
        user.value = data.data.user;
        authTokenExpiresAt.value = data.data.expires_at;
      }
    };

    const socialLogin = async (provider: string, query: LocationQuery) => {
      errors.value = null;
      requesting.value = true;
      authTokenExpiresAt.value = 0;

      const { data, error } = await loginViaAccountProvider(provider, query);

      errors.value = error?.data;
      const message = error?.data?.message;

      if (errors.value && message) {
        toast.error(message);
      }

      if (data) {
        user.value = data.data.user;
        authTokenExpiresAt.value = data.data.expires_at;
      }

      requesting.value = false;
    };

    const getUserSetting = (settingName: UserSettingsType) => {
      if (!user.value) {
        return false;
      }

      const setting = user.value.settings?.find((s) => s.name === settingName);

      if (!setting) {
        return false;
      }

      return setting.setting;
    };

    const getAuthUser = async () => {
      const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

      if (requesting.value || !token.value) {
        return;
      }

      requesting.value = true;
      const { data, error } = await fetchUserFromTokenClient();
      requesting.value = false;

      if (!data || error) {
        if (error?.statusCode === RESPONSE_STATUS.Unauthenticated.statusCode) {
          const cookies = useCookies([COOKIE_KEYS.AuthToken]);

          cookies.remove(COOKIE_KEYS.AuthToken);
          user.value = undefined;
          authUser.value = undefined;
          authUserUpdatedAt.value = undefined;
        }

        return;
      }

      user.value = data.data;
    };

    const sendResetLink = async (email: string, callbackUrl: string) => {
      if (requesting.value) {
        return {
          error: true,
          message: "",
        };
      }

      errors.value = null;
      requesting.value = true;

      const { data, error } = await requestPasswordReset(email, callbackUrl);

      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message = !error.statusCode || error.statusCode !== 422 ? error.data?.message || "There was an error sending the reset link" : "";

        return {
          error: true,
          message,
        };
      }

      return {
        error: false,
        message: data!.message,
      };
    };

    const passwordReset = async (payload: ResetPasswordPayload) => {
      if (requesting.value) {
        return {
          error: true,
          message: "",
        };
      }

      errors.value = null;
      requesting.value = true;

      const { data, error } = await resetPassword(payload);

      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message =
          !error.statusCode || error.statusCode !== 422 ? error.data?.message || "There was an error resetting the account password" : "";

        return {
          error: true,
          message,
        };
      }

      return {
        error: false,
        message: data?.message || "Success!",
      };
    };

    const shouldRefreshAuthUser = () => {
      const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

      if (!token.value) {
        return false;
      }

      return !user.value || !authUserUpdatedAt.value || authUserUpdatedAt.value < getUnixTime(subMinutes(new Date(), AUTH_USER_UPDATE_INTERVAL));
    };

    return {
      user,
      authUser,
      authUserUpdatedAt,
      errors,
      regData,
      authData,
      requesting,
      authTokenExpiresAt,
      isAuthenticated,
      userLearningType,
      isCreatorModeEnabled,
      isValidAuthToken,
      registerUser,
      loginUser,
      socialLogin,
      getUserSetting,
      sendResetLink,
      passwordReset,
      fetchError,
      getAuthUser,
      shouldRefreshAuthUser,
    };
  },
  {
    persist: {
      pick: ["authUser", "authUserUpdatedAt", "authTokenExpiresAt", "isCreatorModeEnabled"],
    },
  },
);
