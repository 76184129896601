/* eslint-disable max-len */
import type { LearningType } from "~/utils/types";
import { CHAT_COMMANDS, GAME_COMMAND_SOURCE_MATERIALS } from "~/constants/chat.constant";

export const LEARNING_TYPE = {
  Visual: "visual",
  Logical: "logical",
  Auditory: "auditory",
  GameBased: "game-based",
  FirstPrinciples: "first-principles",
} as const;

export const hasGameMode = (type: LearningType) => {
  return type === LEARNING_TYPE.GameBased;
};

export const learningTypeTutorPrompt = (learningType: LearningType, mode: string, material?: string) => {
  switch (learningType) {
    case LEARNING_TYPE.Visual:
      return `The learner asking the question is a ${learningType} learner, so when explaining a concept or answering questions remember to explain everything in terms of visual concepts, making use of common visual analogies whenever it is possible. Whenever possible, use visuals such as emojis GIFs, or drawings to illustrate your point. If possible, use graphs, diagrams, or other visual aid to explain concepts. Encourage learners to visualize something when possible. This can be done by describing a scenario or concept in detail and asking them to imagine it. Encourage learners to sketch their thoughts and take notes using different colors.`;
    case LEARNING_TYPE.Logical:
      return `The learner asking the question is a ${learningType} learner, so when explaining a concept or answering questions remember to explain everything in terms of patterns, use logic, reasoning and an easy to follow systemic approach whenever possible. Present information in a logical sequence and provide clear step-by-step approach whenever possible. Employ problem-based learning whenever possible, you can present problems or scenarios and guide the learner through the process of finding a solution. Ask open-ended questions whenever possible and encourage learners to share their thoughts and ideas. Whenever possible, share puzzles related to the topic being discussed and guide the learners through the process of solving them.`;
    case LEARNING_TYPE.GameBased:
      return gameBasedLearningType(mode, material);
    case LEARNING_TYPE.FirstPrinciples:
      return `The student opts to learn from first principles, so certain teaching techniques has to be employed: Employ Socratic questioning, use probing questions that lead to students to uncover fundamentals, create clear connections between atomic concepts and try to emphasize the "why" before "how"; Use visual mappings to help students understand the concepts being discussed; Connect new fundamentals to already understood principles, only use precise analogies that highlight structural similarities and avoid misleading assumptions.`;
    default:
      return `The learner is a/an ${learningType} learner, so when explaining a concept or answering questions remember to factor this in your explanation.`;
  }
};

export const learningTypeLessonPrompt = (learningType: LearningType, material?: string) => {
  switch (learningType) {
    case LEARNING_TYPE.Visual:
      return `The learner is a ${learningType} learner, so when creating lessons, lectures or tutorials remember to explain everything in terms of visual concepts, making use of common visual analogies whenever it is possible. If possible, use visual illustrations and images while generating content. Encourage learners to visualize something when possible. This can be done by describing a scenario or concept in detail and asking them to imagine it. Encourage learners to sketch their thoughts and take notes using different colors.`;
    case LEARNING_TYPE.Logical:
      return `The learner is a ${learningType} learner, so when creating lessons, lectures or tutorials remember to explain everything in terms of patterns, use logic, reasoning and an easy to follow systemic approach whenever possible. Present information in a logical sequence and provide clear step-by-step approach whenever possible. Employ problem-based learning whenever possible, you can present problems or scenarios and guide the learner through the process of finding a solution. Ask open-ended questions whenever possible and encourage learners to share their thoughts and ideas. Whenever possible, share puzzles related to the topic being discussed and guide the learners through the process of solving them.`;
    case LEARNING_TYPE.GameBased:
      return `The learner prefers game-based learning, so you can add some gamification elements to their learning experience when creating lessons, lectures or tutorials, explain the concepts using "${material || GAME_COMMAND_SOURCE_MATERIALS.LordOfTheRings}" as the source material to create narratives, storylines and analogies used to explain and answer any questions the learner asks.`;
    case LEARNING_TYPE.FirstPrinciples:
      return `The student opts to learn from first principles, so certain teaching techniques has to be employed: Employ Socratic questioning, use probing questions that lead to students to uncover fundamentals, create clear connections between atomic concepts and try to emphasize the "why" before "how"; Use visual mappings to help students understand the concepts being discussed; Connect new fundamentals to already understood principles, only use precise analogies that highlight structural similarities and avoid misleading assumptions.`;
    default:
      return `The learner is a/an ${learningType} learner, so when creating lessons, lectures or tutorials remember to factor this in your explanation.`;
  }
};

export const learningTypeAssessmentReportPrompt = (learningType: LearningType) => {
  switch (learningType) {
    case LEARNING_TYPE.Visual:
      return `The student is a ${learningType} learner, so remember to take this into consideration while generating your recommendations, make use of common visual analogies whenever it is possible, tailor your recommendation to the student's visual learning style.`;
    case LEARNING_TYPE.Logical:
      return `The student is a ${learningType} learner, so remember to take this into consideration while generating your recommendations, use logic, reasoning and an easy to follow systemic approach in formulating your recommendations whenever possible.`;
    case LEARNING_TYPE.GameBased:
      return `The student is a game-based learner and enjoys a gamified learning experience so try to add gamification elements whenever possible, while generating your report`;
    case LEARNING_TYPE.FirstPrinciples:
      return `The student likes to learn from first principles, so check their understanding of principles before application, also check if their performance shows an ability to derive higher-level concepts from the basics.`;
    default:
      return `The learner is a/an ${learningType} learner, so remember to factor this while generating your recommendations`;
  }
};

const gameBasedLearningType = (chatMode: string, material?: string) => {
  return chatMode === CHAT_COMMANDS.GameMode
    ? `The learner prefers game-based learning, so you have to play an interactive visual game with the learner to help them learn and understand the educational concepts mentioned in the CONTEXT.
  To play the game, use the source material "${material || "Harry Potter"}" and create storylines based on it's lore. The learner should have to answer a question to navigate through the game, if the CONTEXT is related to programming, you could give them short coding exercises in some of the questions. You MUST ask them the questions one at a time, wait for the learner to answer a question before providing the next one. The game should be narrative rich, descriptive, coherent and enjoyable, and the final result should be piecing together a story. Start by describing the starting point, with an intriguing narrative based on the material provided, and ask the learner what they would like to do. The storyline unravels as we progress step by step. Make sure the storyline is coherent, compelling, and enjoyable. Remember, the questions you ask MUST be related to the CONTEXT and do not repeat questions. The game ends when you deduce that the learner has understood the concepts the CONTEXT is talking about or if they have failed a question three times. The minimum number of questions you should ask in the game is FIVE(5), meaning even if they seem to understand the concept, don't end the game until a minimum of 5 questions has been answered, or 3 questions has been failed.`
    : `The learner prefers game-based learning, so you have to add gamification to their learning experience, explain the concepts using "${material || "Harry Potter"}" as the source material to create narratives, storylines and analogies used to explain and answer any questions the learner asks. Make sure to explain the concepts or answer the questions asked using well-crafted analogies and narratives based on the source material selected, if possible create a storyline which is coherent and enjoyable but not too long to captivate the learner's attention, but also ensure the learner understands your responses. You can break the answers to the questions you were asked down into steps and go through the steps one by one with the learner, ensure they understand by asking them before going to the next step, just to ensure the session is interactive.`;
};
