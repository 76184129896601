
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as account7_45R9ulYiZeZ_45GrHnPC281Y5Zif3_45d2qTALfK0BMjqEUMeta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as _91provider_93aLtDU7RJl7_FfKXIlAtYHLMSodBlsewIpx_45dx0XKsAQMeta } from "/vercel/path0/pages/auth/[provider].vue?macro=true";
import { default as createi0kTG_JYCuhy2oOI7MxKpnrNVi1fb4EzK9mOr0cZweoMeta } from "/vercel/path0/pages/content-suite/create.vue?macro=true";
import { default as indexHQU8kcNtWbliNrJPPbGI9YPPbe8T4VeWMnlRUXqeIbMMeta } from "/vercel/path0/pages/content-suite/index.vue?macro=true";
import { default as create_45content_YBwMbfXLVl0Iw7X1S9vsVJoq8Jy5cS_XGP6ssCDi_45AMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/create-content.vue?macro=true";
import { default as edit_45content8KkojskElCqXIAdQgJt90pcNHGG5i2gyqMIhGP6SZKgMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit-content.vue?macro=true";
import { default as editKEdiSTeEzkA_8s0OjEZZxpMi5_45m0aGUw3vo1mqdQA_45wMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit.vue?macro=true";
import { default as indexdqzkZhjKlc0hYesSKnaHjRF26_m5981noHdu7cvNXycMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/index.vue?macro=true";
import { default as continue_45journeyAWCKaf41OquKvfUxKG0B3wbVuhJXtGBFLjqAkMQncHsMeta } from "/vercel/path0/pages/continue-journey.vue?macro=true";
import { default as _91course_93sNT9MaJZjqfTmuUHKUJhuhOsIGOJQLrKQP5xo2_45jiCUMeta } from "/vercel/path0/pages/courses/[course].vue?macro=true";
import { default as indexNxrFsV52aST6D2YVduWMv08YDyNArfWDLGCwGhjN44oMeta } from "/vercel/path0/pages/courses/analytics/courses/[course]/index.vue?macro=true";
import { default as indexuHv611HKleeCbYLoYg_45vQg8BKgk_1Zxcn9IIKUtdwmYMeta } from "/vercel/path0/pages/courses/analytics/courses/index.vue?macro=true";
import { default as indexUkJbDa91U8_45mBDXyJHgjbUfQpUVXzjsro36kFxmEjMMMeta } from "/vercel/path0/pages/courses/analytics/index.vue?macro=true";
import { default as categoriesvvJ3XlbDVYgsfxHBzEqqH99i_450Nt_XolIFeEYiX_whIMeta } from "/vercel/path0/pages/courses/categories.vue?macro=true";
import { default as _91challengeCourse_930C3hWunXRNKDW4TVkQ3teGmm43tgbvY9q7z_45Es8OvN4Meta } from "/vercel/path0/pages/courses/challenges/[challengeCourse].vue?macro=true";
import { default as conceptsIbYxJsV7k3P1aVTnRjFnjcDKWMOrxB6LBhAM410GJ1UMeta } from "/vercel/path0/pages/courses/concepts.vue?macro=true";
import { default as _91course_938Jh_y9fdjKm4jD19lPXw8sKo0kwTNJRwy_CN1RFui3kMeta } from "/vercel/path0/pages/courses/content/[course].vue?macro=true";
import { default as index_453vKzg5pRjiRBJdQDSFFQvm77bmPDSliKHE3myhEzM8Meta } from "/vercel/path0/pages/courses/index.vue?macro=true";
import { default as feedCzRajSqu7_FDDJo1ZS2gVajINXx41WpnTn5ZSRRj8pgMeta } from "/vercel/path0/pages/feed.vue?macro=true";
import { default as creatorskNmqHai_45InEFjy7PLUE6PgdVbIVzd4Jh0d3u_45UaBzX8Meta } from "/vercel/path0/pages/guides/creators.vue?macro=true";
import { default as learnersu30zy39EQOShJk1c8it3cC8Wt78ujUANAuaWz13GXGAMeta } from "/vercel/path0/pages/guides/learners.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as completed_45coursesF6Am7Qid_3xuO24w5VtglzQ1DOVDxLh4A7Y2B_45GR8m8Meta } from "/vercel/path0/pages/library/completed-courses.vue?macro=true";
import { default as ongoing_45coursesVLO26A8fmP0u_YwNDGP8weqE0TiBsi8I0x5P9VZ0AvcMeta } from "/vercel/path0/pages/library/ongoing-courses.vue?macro=true";
import { default as watchlistScX5GW4XmtCnqX4mjuF247vS7E1gsAzhB1My6g__45RskMeta } from "/vercel/path0/pages/library/watchlist.vue?macro=true";
import { default as _91mentor_93wYytfqSzowWYZOuG9GhWcAt28cy1IzjoFKpW2juopNYMeta } from "/vercel/path0/pages/mentors/[mentor].vue?macro=true";
import { default as createls2fmYyv8DPkGB8_srus5Uxy9zvkzbE16v_454qjXsQAYMeta } from "/vercel/path0/pages/mentors/create.vue?macro=true";
import { default as indexMskjvUE6ieKQAN3Y0Hd3v7tNkXwB51qeBhTx6noCAdgMeta } from "/vercel/path0/pages/mentors/index.vue?macro=true";
import { default as new_45journeyrFsX7qF9qa3HrDrL4IoUIetGxfMiKyeOPEc4bXRWGNIMeta } from "/vercel/path0/pages/new-journey.vue?macro=true";
import { default as _91note_93VdFmBpDF1_45xX3TTyb_45_bdxM2xfmfywTH6FQz_45hiIzcMMeta } from "/vercel/path0/pages/notes/[note].vue?macro=true";
import { default as createooRpoEzEQMek4luu4eHWmcBcwfvhQg7rboyx4EjpYmEMeta } from "/vercel/path0/pages/notes/create.vue?macro=true";
import { default as index7kooq5kudl58w_2n6a6IgFqQhBQD5qOdVFOG5Q7VGywMeta } from "/vercel/path0/pages/notes/index.vue?macro=true";
import { default as request_45resetqvPlL52GwJ_khjEy2eiELAifFNesDU4WWzOe5LmP_45QkMeta } from "/vercel/path0/pages/password/request-reset.vue?macro=true";
import { default as reset_45passwordWqZZSrbG10ioSvauKHCbL0hTRdpgkeS4EcfLfJkyAMQMeta } from "/vercel/path0/pages/password/reset-password.vue?macro=true";
import { default as _91assessment_93jxLQYuHq9CbfEMYwX3KOu66TKnFuhBVBuv0S_45c1alpUMeta } from "/vercel/path0/pages/performance-reviews/[assessment].vue?macro=true";
import { default as _91courseAssessment_934ujwvCxPQoxIVFNSHPyWh5l6kzTL9xf48ImE6egbrhcMeta } from "/vercel/path0/pages/performance-reviews/courses/[courseAssessment].vue?macro=true";
import { default as indexsi5hsjMChoN4sw8_9Jxmh50e4pVPrkexHm57V_Dm0_AMeta } from "/vercel/path0/pages/performance-reviews/courses/index.vue?macro=true";
import { default as index0XKft3nyq6o2A1bMai0x4BB8afScqvwxl0bMXrVjkAMMeta } from "/vercel/path0/pages/performance-reviews/index.vue?macro=true";
import { default as privacyoUxJze_459YVMN19fHCMNcOPA4bNg1TjmwARd6j9aojjEMeta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as courseswBBSDJMvNZSYyaHacO0FZ_45pnsoebqoU4axkc_455fq5cgMeta } from "/vercel/path0/pages/profile/[username]/courses.vue?macro=true";
import { default as indexWC1tB_6ntyDobZRu0QoAxC_wH21_459n1icjRHZ_45vHuIcMeta } from "/vercel/path0/pages/profile/[username]/index.vue?macro=true";
import { default as setup_45profileno9kYppUwCpaWgA9z9eZJTux0ZeiDO5wVMXeCDzyhWwMeta } from "/vercel/path0/pages/setup-profile.vue?macro=true";
import { default as engagement_45pointsqzTikjya5EJv2T_45Wtbjg8mE_ZtFJzY5gp92oaf4P_KQMeta } from "/vercel/path0/pages/store/engagement-points.vue?macro=true";
import { default as create_45materialMusuNTKi5kjTQ9A1X_45sWXbVrFrPfsfvLdtmxPg5vzscMeta } from "/vercel/path0/pages/study-quests/[quest]/create-material.vue?macro=true";
import { default as editQEiE2zoct7c8GvPy3sLQ3Tpm0a8hYNR_45_45yoZTEcVhJ0Meta } from "/vercel/path0/pages/study-quests/[quest]/edit.vue?macro=true";
import { default as indexYoLElqyDUhO_45LyRA2rV8h7chBO4OmwYIiHVQ5ZhIqxwMeta } from "/vercel/path0/pages/study-quests/[quest]/index.vue?macro=true";
import { default as studyP4EXVEmPXjB4yh5qc0NJTNIYr3v0RCeTLEHNQ3XSTl4Meta } from "/vercel/path0/pages/study-quests/[quest]/study.vue?macro=true";
import { default as indexH5tHpCovnrj9loEcRi1Ab9MlbMWaLgHI0anIdFwDT64Meta } from "/vercel/path0/pages/study-quests/index.vue?macro=true";
import { default as new_45questocfpHgKAGcnKLnttS0z31etz4k_45_nbfwOkTQ98MEBJAMeta } from "/vercel/path0/pages/study-quests/new-quest.vue?macro=true";
import { default as terms_45of_45serviceQuvQu15QrOEvfk8Simejfn_Ba6eU187th49_hXEj6RQMeta } from "/vercel/path0/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: account7_45R9ulYiZeZ_45GrHnPC281Y5Zif3_45d2qTALfK0BMjqEUMeta || {},
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "auth-provider",
    path: "/auth/:provider()",
    meta: _91provider_93aLtDU7RJl7_FfKXIlAtYHLMSodBlsewIpx_45dx0XKsAQMeta || {},
    component: () => import("/vercel/path0/pages/auth/[provider].vue")
  },
  {
    name: "content-suite-create",
    path: "/content-suite/create",
    meta: createi0kTG_JYCuhy2oOI7MxKpnrNVi1fb4EzK9mOr0cZweoMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/create.vue")
  },
  {
    name: "content-suite",
    path: "/content-suite",
    meta: indexHQU8kcNtWbliNrJPPbGI9YPPbe8T4VeWMnlRUXqeIbMMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/index.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-create-content",
    path: "/content-suite/manage-course/:managedCourse()/create-content",
    meta: create_45content_YBwMbfXLVl0Iw7X1S9vsVJoq8Jy5cS_XGP6ssCDi_45AMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/create-content.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-edit-content",
    path: "/content-suite/manage-course/:managedCourse()/edit-content",
    meta: edit_45content8KkojskElCqXIAdQgJt90pcNHGG5i2gyqMIhGP6SZKgMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit-content.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-edit",
    path: "/content-suite/manage-course/:managedCourse()/edit",
    meta: editKEdiSTeEzkA_8s0OjEZZxpMi5_45m0aGUw3vo1mqdQA_45wMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse",
    path: "/content-suite/manage-course/:managedCourse()",
    meta: indexdqzkZhjKlc0hYesSKnaHjRF26_m5981noHdu7cvNXycMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/index.vue")
  },
  {
    name: "continue-journey",
    path: "/continue-journey",
    meta: continue_45journeyAWCKaf41OquKvfUxKG0B3wbVuhJXtGBFLjqAkMQncHsMeta || {},
    component: () => import("/vercel/path0/pages/continue-journey.vue")
  },
  {
    name: "courses-course",
    path: "/courses/:course()",
    meta: _91course_93sNT9MaJZjqfTmuUHKUJhuhOsIGOJQLrKQP5xo2_45jiCUMeta || {},
    component: () => import("/vercel/path0/pages/courses/[course].vue")
  },
  {
    name: "courses-analytics-courses-course",
    path: "/courses/analytics/courses/:course()",
    meta: indexNxrFsV52aST6D2YVduWMv08YDyNArfWDLGCwGhjN44oMeta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/courses/[course]/index.vue")
  },
  {
    name: "courses-analytics-courses",
    path: "/courses/analytics/courses",
    meta: indexuHv611HKleeCbYLoYg_45vQg8BKgk_1Zxcn9IIKUtdwmYMeta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/courses/index.vue")
  },
  {
    name: "courses-analytics",
    path: "/courses/analytics",
    meta: indexUkJbDa91U8_45mBDXyJHgjbUfQpUVXzjsro36kFxmEjMMMeta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/index.vue")
  },
  {
    name: "courses-categories",
    path: "/courses/categories",
    meta: categoriesvvJ3XlbDVYgsfxHBzEqqH99i_450Nt_XolIFeEYiX_whIMeta || {},
    component: () => import("/vercel/path0/pages/courses/categories.vue")
  },
  {
    name: "courses-challenges-challengeCourse",
    path: "/courses/challenges/:challengeCourse()",
    meta: _91challengeCourse_930C3hWunXRNKDW4TVkQ3teGmm43tgbvY9q7z_45Es8OvN4Meta || {},
    component: () => import("/vercel/path0/pages/courses/challenges/[challengeCourse].vue")
  },
  {
    name: "courses-concepts",
    path: "/courses/concepts",
    meta: conceptsIbYxJsV7k3P1aVTnRjFnjcDKWMOrxB6LBhAM410GJ1UMeta || {},
    component: () => import("/vercel/path0/pages/courses/concepts.vue")
  },
  {
    name: "courses-content-course",
    path: "/courses/content/:course()",
    meta: _91course_938Jh_y9fdjKm4jD19lPXw8sKo0kwTNJRwy_CN1RFui3kMeta || {},
    component: () => import("/vercel/path0/pages/courses/content/[course].vue")
  },
  {
    name: "courses",
    path: "/courses",
    meta: index_453vKzg5pRjiRBJdQDSFFQvm77bmPDSliKHE3myhEzM8Meta || {},
    component: () => import("/vercel/path0/pages/courses/index.vue")
  },
  {
    name: "feed",
    path: "/feed",
    meta: feedCzRajSqu7_FDDJo1ZS2gVajINXx41WpnTn5ZSRRj8pgMeta || {},
    component: () => import("/vercel/path0/pages/feed.vue")
  },
  {
    name: "guides-creators",
    path: "/guides/creators",
    meta: creatorskNmqHai_45InEFjy7PLUE6PgdVbIVzd4Jh0d3u_45UaBzX8Meta || {},
    component: () => import("/vercel/path0/pages/guides/creators.vue")
  },
  {
    name: "guides-learners",
    path: "/guides/learners",
    meta: learnersu30zy39EQOShJk1c8it3cC8Wt78ujUANAuaWz13GXGAMeta || {},
    component: () => import("/vercel/path0/pages/guides/learners.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "library-completed-courses",
    path: "/library/completed-courses",
    meta: completed_45coursesF6Am7Qid_3xuO24w5VtglzQ1DOVDxLh4A7Y2B_45GR8m8Meta || {},
    component: () => import("/vercel/path0/pages/library/completed-courses.vue")
  },
  {
    name: "library-ongoing-courses",
    path: "/library/ongoing-courses",
    meta: ongoing_45coursesVLO26A8fmP0u_YwNDGP8weqE0TiBsi8I0x5P9VZ0AvcMeta || {},
    component: () => import("/vercel/path0/pages/library/ongoing-courses.vue")
  },
  {
    name: "library-watchlist",
    path: "/library/watchlist",
    meta: watchlistScX5GW4XmtCnqX4mjuF247vS7E1gsAzhB1My6g__45RskMeta || {},
    component: () => import("/vercel/path0/pages/library/watchlist.vue")
  },
  {
    name: "mentors-mentor",
    path: "/mentors/:mentor()",
    meta: _91mentor_93wYytfqSzowWYZOuG9GhWcAt28cy1IzjoFKpW2juopNYMeta || {},
    component: () => import("/vercel/path0/pages/mentors/[mentor].vue")
  },
  {
    name: "mentors-create",
    path: "/mentors/create",
    meta: createls2fmYyv8DPkGB8_srus5Uxy9zvkzbE16v_454qjXsQAYMeta || {},
    component: () => import("/vercel/path0/pages/mentors/create.vue")
  },
  {
    name: "mentors",
    path: "/mentors",
    meta: indexMskjvUE6ieKQAN3Y0Hd3v7tNkXwB51qeBhTx6noCAdgMeta || {},
    component: () => import("/vercel/path0/pages/mentors/index.vue")
  },
  {
    name: "new-journey",
    path: "/new-journey",
    meta: new_45journeyrFsX7qF9qa3HrDrL4IoUIetGxfMiKyeOPEc4bXRWGNIMeta || {},
    component: () => import("/vercel/path0/pages/new-journey.vue")
  },
  {
    name: "notes-note",
    path: "/notes/:note()",
    meta: _91note_93VdFmBpDF1_45xX3TTyb_45_bdxM2xfmfywTH6FQz_45hiIzcMMeta || {},
    component: () => import("/vercel/path0/pages/notes/[note].vue")
  },
  {
    name: "notes-create",
    path: "/notes/create",
    meta: createooRpoEzEQMek4luu4eHWmcBcwfvhQg7rboyx4EjpYmEMeta || {},
    component: () => import("/vercel/path0/pages/notes/create.vue")
  },
  {
    name: "notes",
    path: "/notes",
    meta: index7kooq5kudl58w_2n6a6IgFqQhBQD5qOdVFOG5Q7VGywMeta || {},
    component: () => import("/vercel/path0/pages/notes/index.vue")
  },
  {
    name: "password-request-reset",
    path: "/password/request-reset",
    meta: request_45resetqvPlL52GwJ_khjEy2eiELAifFNesDU4WWzOe5LmP_45QkMeta || {},
    component: () => import("/vercel/path0/pages/password/request-reset.vue")
  },
  {
    name: "password-reset-password",
    path: "/password/reset-password",
    meta: reset_45passwordWqZZSrbG10ioSvauKHCbL0hTRdpgkeS4EcfLfJkyAMQMeta || {},
    component: () => import("/vercel/path0/pages/password/reset-password.vue")
  },
  {
    name: "performance-reviews-assessment",
    path: "/performance-reviews/:assessment()",
    meta: _91assessment_93jxLQYuHq9CbfEMYwX3KOu66TKnFuhBVBuv0S_45c1alpUMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/[assessment].vue")
  },
  {
    name: "performance-reviews-courses-courseAssessment",
    path: "/performance-reviews/courses/:courseAssessment()",
    meta: _91courseAssessment_934ujwvCxPQoxIVFNSHPyWh5l6kzTL9xf48ImE6egbrhcMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/courses/[courseAssessment].vue")
  },
  {
    name: "performance-reviews-courses",
    path: "/performance-reviews/courses",
    meta: indexsi5hsjMChoN4sw8_9Jxmh50e4pVPrkexHm57V_Dm0_AMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/courses/index.vue")
  },
  {
    name: "performance-reviews",
    path: "/performance-reviews",
    meta: index0XKft3nyq6o2A1bMai0x4BB8afScqvwxl0bMXrVjkAMMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyoUxJze_459YVMN19fHCMNcOPA4bNg1TjmwARd6j9aojjEMeta || {},
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "profile-username-courses",
    path: "/profile/:username()/courses",
    meta: courseswBBSDJMvNZSYyaHacO0FZ_45pnsoebqoU4axkc_455fq5cgMeta || {},
    component: () => import("/vercel/path0/pages/profile/[username]/courses.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: indexWC1tB_6ntyDobZRu0QoAxC_wH21_459n1icjRHZ_45vHuIcMeta || {},
    component: () => import("/vercel/path0/pages/profile/[username]/index.vue")
  },
  {
    name: "setup-profile",
    path: "/setup-profile",
    meta: setup_45profileno9kYppUwCpaWgA9z9eZJTux0ZeiDO5wVMXeCDzyhWwMeta || {},
    component: () => import("/vercel/path0/pages/setup-profile.vue")
  },
  {
    name: "store-engagement-points",
    path: "/store/engagement-points",
    meta: engagement_45pointsqzTikjya5EJv2T_45Wtbjg8mE_ZtFJzY5gp92oaf4P_KQMeta || {},
    component: () => import("/vercel/path0/pages/store/engagement-points.vue")
  },
  {
    name: "study-quests-quest-create-material",
    path: "/study-quests/:quest()/create-material",
    meta: create_45materialMusuNTKi5kjTQ9A1X_45sWXbVrFrPfsfvLdtmxPg5vzscMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/create-material.vue")
  },
  {
    name: "study-quests-quest-edit",
    path: "/study-quests/:quest()/edit",
    meta: editQEiE2zoct7c8GvPy3sLQ3Tpm0a8hYNR_45_45yoZTEcVhJ0Meta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/edit.vue")
  },
  {
    name: "study-quests-quest",
    path: "/study-quests/:quest()",
    meta: indexYoLElqyDUhO_45LyRA2rV8h7chBO4OmwYIiHVQ5ZhIqxwMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/index.vue")
  },
  {
    name: "study-quests-quest-study",
    path: "/study-quests/:quest()/study",
    meta: studyP4EXVEmPXjB4yh5qc0NJTNIYr3v0RCeTLEHNQ3XSTl4Meta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/study.vue")
  },
  {
    name: "study-quests",
    path: "/study-quests",
    meta: indexH5tHpCovnrj9loEcRi1Ab9MlbMWaLgHI0anIdFwDT64Meta || {},
    component: () => import("/vercel/path0/pages/study-quests/index.vue")
  },
  {
    name: "study-quests-new-quest",
    path: "/study-quests/new-quest",
    meta: new_45questocfpHgKAGcnKLnttS0z31etz4k_45_nbfwOkTQ98MEBJAMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/new-quest.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45serviceQuvQu15QrOEvfk8Simejfn_Ba6eU187th49_hXEj6RQMeta || {},
    component: () => import("/vercel/path0/pages/terms-of-service.vue")
  }
]