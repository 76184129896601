/* eslint-disable max-len */
export const CONTENT_SECTIONS = {
  Objectives: "objectives",
  Introduction: "introduction",
  Foundation: "foundation",
  IndepthStudy: "study",
  Practice: "practice",
  PracticalApplication: "application",
  Summary: "summary",
  NextSteps: "next_steps",
} as const;

export const LESSON_CONTENT_STRUCTURE = {
  Objectives: {
    key: "objectives",
    model: "content",
    field: "objectives",
    action: "update",
    title: "Objectives",
    objective:
      "Help me understand the objectives of the lesson, what I am expected to understand or master, as well as what I should be able to do by the end of the lesson",
    steps: [],
  },
  Introduction: {
    key: "introduction",
    model: "content",
    field: "introduction",
    action: "update",
    title: "Introduction and Overview",
    objective: "Help me understand the purpose and scope of the content.",
    steps: [
      "Provide me with an introduction to the subject or skill to be studied as well as the preface",
      "Provide me with the themes and goals for the subject to be studied.",
    ],
  },
  Foundation: {
    key: "foundation",
    model: "content",
    field: "content",
    action: "append",
    title: "Foundation Knowledge",
    objective:
      "Help me build a strong base of essential concepts, and overall a great understanding of all the fundamentals of the topic of discussion",
    steps: [
      "Provide and help me understand the basic terminologies.",
      "Generate the fundamental theories or principles, and try to take a practical approach when possible, by explaining the application of these theories in the real-world.",
      "Use visual aids (charts, mind maps, images) for better retention whenever necessary.",
    ],
  },
  IndepthStudy: {
    key: "study",
    model: "content",
    field: "content",
    action: "append",
    title: "In-Depth Study Content",
    objective: "Delve deeper into the lesson's main topics. Emphasize practice and practical applications",
    steps: [
      "Explain the concepts in more detail, and provide examples and applications.",
      "Break down the concepts into sections and explain them in detail, such that they are easy to pick up and apply.",
      "Remember to prioritize practical applications when possible.",
    ],
  },
  Practice: {
    key: "practice",
    model: "quiz",
    field: "quiz_question",
    action: "create",
    title: "Practice and Reinforcement",
    objective: "Help me reinforce my knowledge & understanding through continuous practice.",
    steps: [
      "Generate practice problems related to the topic for me to answer",
      "When possible, create fictional scenarios where I have a problem and have to apply the concepts of this topic to solve it.",
    ],
  },
  PracticalApplication: {
    key: "application",
    model: "task",
    field: "description",
    action: "create",
    title: "Practical Application",
    objective:
      "Generate tasks and projects based on real-world scenarios for me that I can practice or perform to allow me further apply concepts they have learnt.",
    steps: [
      "Use relevant case studies, examples, or practical exercises where applicable",
      "You can generate simulations or role-playing activities that will allow me apply what I have learnt. Do this especially if the knowledge that can't directly be practiced (like a pilot) or if I selected the 'simulation' study approach",
      "If I also selected a project based study approach, then you can generate projects for me based on what I have learnt that I can practice. The project should be directly related to the topic of discussion.",
    ],
  },
  Summary: {
    key: "summary",
    model: "content",
    field: "summary",
    action: "update",
    title: "Lesson Summary",
    objective: "Generate a summary of the lesson and the concepts I have encountered while studying the topic of discussion so far.",
    steps: [],
  },
  NextSteps: {
    key: "next_steps",
    model: "content",
    field: "next_steps",
    action: "update",
    title: "Next Steps",
    objective:
      "Based on the knowledge acquired so far, suggest the next steps I should take to deepen my understanding and apply what I've learned. Provide a detailed plan including relevant projects, and any other activities that would help me further enhance my skills and knowledge in this area.",
    steps: [],
  },
} as const;

export const STUDY_CONTENT_SECTIONS = [
  {
    id: "content." + CONTENT_SECTIONS.Objectives,
    category: "content",
    name: CONTENT_SECTIONS.Objectives,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Introduction,
    category: "content",
    name: CONTENT_SECTIONS.Introduction,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Foundation,
    category: "content",
    name: CONTENT_SECTIONS.Foundation,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.IndepthStudy,
    category: "content",
    name: CONTENT_SECTIONS.IndepthStudy,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Practice,
    category: "content",
    name: CONTENT_SECTIONS.Practice,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.PracticalApplication,
    category: "content",
    name: CONTENT_SECTIONS.PracticalApplication,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Summary,
    category: "content",
    name: CONTENT_SECTIONS.Summary,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.NextSteps,
    category: "content",
    name: CONTENT_SECTIONS.NextSteps,
    canAskQuestions: true,
    canSkip: false,
  },
] as const;

export const STUDY_CONTENT_TYPE = {
  Activity: "activity",
  Analysis: "analysis",
  Example: "example",
  Explanation: "explanation",
  Game: "game",
  Lecture: "lecture",
  Lesson: "lesson",
} as const;

export const getContentPromptStructureFromSection = (section: string) => {
  switch (section) {
    case CONTENT_SECTIONS.Objectives: {
      return LESSON_CONTENT_STRUCTURE.Objectives;
    }

    case CONTENT_SECTIONS.Introduction: {
      return LESSON_CONTENT_STRUCTURE.Introduction;
    }

    case CONTENT_SECTIONS.Foundation: {
      return LESSON_CONTENT_STRUCTURE.Foundation;
    }

    case CONTENT_SECTIONS.IndepthStudy: {
      return LESSON_CONTENT_STRUCTURE.IndepthStudy;
    }

    case CONTENT_SECTIONS.Practice: {
      return LESSON_CONTENT_STRUCTURE.Practice;
    }

    case CONTENT_SECTIONS.PracticalApplication: {
      return LESSON_CONTENT_STRUCTURE.PracticalApplication;
    }

    case CONTENT_SECTIONS.Summary: {
      return LESSON_CONTENT_STRUCTURE.Summary;
    }

    case CONTENT_SECTIONS.NextSteps: {
      return LESSON_CONTENT_STRUCTURE.NextSteps;
    }

    default: {
      throw new Error("Unrecognized section for section '" + section + "'");
    }
  }
};
