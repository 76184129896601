export const USER_SETTINGS = {
  LearningType: "learning_type",
  ContentTypes: "preferred_content_type",
  EnableTutorChat: "enable_tutor_chat",
  EnableAssessments: "enable_assessments",
  AutoPlayNextVideo: "auto_play_next_video",
  SetupProgress: "profile_setup_progress",
  EnableReportGeneration: "auto_assessment_report_generation",
  PreferredModelProvider: "preferred_model_provider",
} as const;
