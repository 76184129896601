import { RESPONSE_STATUS } from "~/constants";

export default defineNuxtRouteMiddleware(async (to) => {
  if (!import.meta.dev && to.path.includes("study-quests")) {
    throw createError({
      ...RESPONSE_STATUS.NotFound,
      fatal: true,
    });
  }
});
